<template>
  <div class="kt-grid kt-grid--ver kt-grid--root">
    <div
      class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
      id="kt_login"
    >
      <Ribbon />

      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
      >
        <!--begin::Aside-->
        <div
          class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
        >
          <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
            <div class="kt-grid__item kt-grid__item--middle">
              <h3 class="kt-login__title">Welcome to Voxeus</h3>
              <h4 class="kt-login__subtitle">
                Voxeus, the audio distribution and monetization technology for
                publishers.
              </h4>
            </div>
          </div>
          <div class="kt-grid__item">
            <div class="kt-login__info">
              <div class="kt-login__copyright">© {{ currentYear }} Voxeus</div>
              <div class="kt-login__menu">
                <a :href="piDomain + 'privacy'" class="kt-link" target="_blank"
                  >Privacy</a
                >
                <a :href="piDomain + 'legal'" class="kt-link" target="_blank"
                  >Legal</a
                >
                <a :href="piDomain + 'terms'" class="kt-link" target="_blank"
                  >Terms</a
                >
              </div>
            </div>
          </div>
        </div>
        <!--begin::Aside-->

        <!--begin::Content-->
        <div
          class="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
        >
          <div
            style="background: #ffffff;"
            class="d-flex justify-content-between align-items-center flex-column flex-sm-row mb-2"
          >
            <img
              src="assets/media/logos/logo-voxeus-black.png"
              alt="Logo platform"
              width="145"
            />
          </div>
          <router-view></router-view>
        </div>
        <!--end::Content-->
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login.scss";
</style>

<script>
import { mapState } from "vuex";
import Config from "@/common/config";
import Ribbon from "@/components/ribbonPreprod";

export default {
  name: "auth",
  methods: {},
  components: {
    Ribbon
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      piDomain: Config.get("platform_listening")
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
